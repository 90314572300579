import Vue from 'vue/dist/vue.esm'
import template from './score_all_panel_template.slim'
import _ from 'lodash'

Vue.component('score-all-panel', {
  mixins: [template],
  props: ['scoring', 'on_press_save', 'on_press_cancel', 'score_band'],
  data() {
    return {
      score: null,
      clearScoresDisabled: false,
    }
  },
  methods: {
    updateScores(value) {
      this.clearScoresDisabled = false
      this.score = value
    },
    isSelected(value) {
      return this.score === value
    },
    onPressClearAll() {
      this.score = null
      this.clearScoresDisabled = true
    },
    onPressSave() {
      this.clearScoresDisabled = false
      this.on_press_save(this.score)
    },
    onPressCancel() {
      this.clearScoresDisabled = false
      this.on_press_cancel()
    },
  },
})
