import Vue from 'vue/dist/vue.esm'
import template from './objective_cards_panel_template.slim'
import { EventBus } from '../../../../event-bus.js'
import './components/objective_card'
import _ from 'lodash'

Vue.component('objective-cards-panel', {
  mixins: [template],
  props: ['objectives', 'view_all_objectives'],
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    onPressLaunchSession() {
      EventBus.$emit('open-objectives-session-modal', this.selected)
    },
    updateSelected(event, objective) {
      if (event.target.checked) {
        this.selected.push(objective)
      } else {
        this.selected = _.without(this.selected, objective)
      }
    },
  },
})
