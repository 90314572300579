import Vue from 'vue/dist/vue.esm'
import template from './tracking_grid_template.slim'
import './components/tracking_panel_item'
import _ from 'lodash'

Vue.component('tracking-grid', {
  mixins: [template],
  props: [
    'panels',
    'panel_items',
    'get_panel_title',
    'get_item_title',
    'on_press_panel_title',
    'on_press_info_icon',
    'update_scores',
    'get_score_params',
    'get_score',
    'add_note',
    'on_press_add_student_note',
    'penultimate_score_classes',
    'score_band'
  ],
  methods: {
    setHeight() {
      const windowHeight = $(window).height()
      const offset = $('#navigation').height() + $('#track-header').height()
      $(this.$el)
        .find('.grid-panel')
        .height(windowHeight - offset)
    },
    goToPanel(panel) {
      const index = _.indexOf(this.panels, panel)
      this.$refs.gridCarousel.goToPage(index)
    },
    getPenultimateScoreClass(obj, student) {
      const parsedScoreClasses = JSON.parse(this.penultimate_score_classes)
      const scoreClass = _.find(
        parsedScoreClasses,
        this.byStudentObjective(student.id, obj.id)
      )
      if (scoreClass) return scoreClass.score_class
    },
    byStudentObjective(studentId, objectiveId) {
      return function(s) {
        return s.student_id == studentId && s.objective_id == objectiveId
      }
    },
  },
  mounted() {
    this.setHeight()
    $(window).on('orientationchange', () => {
      setTimeout(this.setHeight, 100)
    })
  },
})
