import Vue from 'vue/dist/vue.esm'
import template from './checkbox_panels_template.slim'
require('./checkbox_panel')

Vue.component('checkbox-panels', {
  data() {
    return { panels: [], current: null }
  },
  mixins: [template],
  methods: {
    addPanel(panel) {
      this.panels.push(panel)
    },
    changePanel(selected) {
      this.current = selected
      this.panels.forEach(function(panel) {
        panel.active = selected === panel
      })
    },
  },
})
