import Vue from 'vue/dist/vue.esm'
import template from './assessors_multiselect_template.slim'
import withScrollToTop from '../../../mixins/with_scroll_to_top.js'

Vue.component('assessors-multiselect', {
  mixins: [template, withScrollToTop],
  props: [
    'value',
    'assessors_options',
    'errors',
    'required',
    'current_staff_name',
  ],
  data() {
    return {
      hasFocus: false,
    }
  },
  methods: {
    updateAssessors(value) {
      this.$emit('input', value)
    },
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      } else {
        this.$refs.assessorsSelect.$el.focus()
      }
      this.hasFocus = !this.hasFocus
    },
    disableRemoveCurrentStaff() {
      // remove the x remove button from the current staff's tag
      const $targetTag = $(
        `.multiselect__tag:contains('${this.current_staff_name}')`
      )
      $targetTag.find('.multiselect__tag-icon').remove()
      $targetTag.css({ padding: '9px 15px 9px 15px' })
    },
  },
  mounted() {
    this.disableRemoveCurrentStaff()
  },
  updated() {
    this.disableRemoveCurrentStaff()
  },
})
