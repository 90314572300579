import Vue from 'vue/dist/vue.esm'
import template from './checkbox_radio_input_template.slim'

Vue.component('checkbox-radio-input', {
  mixins: [template],
  props: ['name', 'label', 'value'],
  computed: {
    checkboxRadioInputValue: {
      get: function() {
        return this.value
      },
      set: function() {
        this.$emit('change', this.label)
      },
    },
  },
})
