import Vue from 'vue/dist/vue.esm'
import template from './name_date_template.slim'
import { EventBus } from '../../../../event-bus.js'

Vue.component('name-date', {
  mixins: [template],
  props: ['form', 'sessionOptions', 'assessors', 'assessorOptions'],
  data() {
    return {
      filterBy: '',
    }
  },
  methods: {
    clearInput() {
      this.filterBy = ''
    },
    determineSelected(id) {
      return this.assessors.slice().includes(id)
    },
    returnFiltered() {
      return this.assessorOptions.filter(obj => {
        return obj.label.toLowerCase().includes(this.filterBy.toLowerCase())
      })
    },
    selectAssessor(id) {
      if (this.sessionOptions.current_staff.value === id) {
        alert('You may not remove yourself as an observer')
        return
      }
      EventBus.$emit('select-assessor', id)
    },
  },
  mounted() {},
})
