import Vue from 'vue/dist/vue.esm'
import template from './historical_score_card_template.slim'

Vue.component('historical_score_card', {
  mixins: [template],
  props: [
    'score',
    'score_band'
  ],
  computed: {
    scoreClass() {
      switch (this.score.display) {
        case 'Not Yet':
          return `score-band-${this.score_band}-no`
        case 'Somewhat':
          return `score-band-${this.score_band}-somewhat`
        case 'Mostly':
          return `score-band-${this.score_band}-mostly`
        case 'Yes':
          return `score-band-${this.score_band}-yes`
        case 'Exemplary':
          return `score-band-${this.score_band}-exemplary`
        default:
          return 'clear'
      }
    },
  },
})
