import Vue from 'vue/dist/vue.esm'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import template from './student_objectives_report_template.slim'

const NBSP = '\xA0';

Vue.component('student-objectives-report', {
  mixins: [template],
  components: {
    VueGoodTable,
  },
  props: ['objective_rows'],
  data() {
    return {
      columns: [
        {
          label: `Mastery${NBSP}Component / Mastery${NBSP}Objective`,
          field: 'short_name',
          tdClass: 'mastery-objective-name',
        },
        {
          label: 'Mastery Component',
          field: 'mastery_component_name',
          hidden: true,
        },
        {
          label: 'Last Score',
          field: 'last_score.score',
          thClass: 'centered',
          tdClass: 'centered',
        },
        {
          label: 'Recent Scores',
          field: 'scores',
          thClass: 'centered objective-validity',
          tdClass: 'centered objective-validity',
          width: '180px',
        },
        {
          label: 'Score Date',
          field: 'last_score.updated_at',
          formatFn: this.report_session_date,
        },
        {
          label: 'Observer',
          field: 'last_score.staff_full_name',
        },
        {
          label: 'Session Name',
          field: 'last_score.session_name',
        },
      ],
    }
  },
  methods: {
    report_session_date(date) {
      return moment(date).format('M/D/YYYY');
    }
  }
})
