import Vue from 'vue/dist/vue.esm'
import template from './title_input_template.slim'

Vue.component('title-input', {
  mixins: [template],
  props: ['value', 'errors'],
  methods: {
    updateTitle() {
      this.$emit('input', this.$refs.titleInput.value)
    },
  },
})
