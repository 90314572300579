import Vue from 'vue/dist/vue.esm'
import template from './track_header_template.slim'

Vue.component('track-header', {
  mixins: [template],
  props: [
    'date',
    'title',
    'scoring_by',
    'open_change_score_by_menu',
    'open_session_note_modal',
    'open_session_edit_panel',
    'hasNotes',
  ],
})
