
    module.exports = {
      render: function(){with(this){return _c('div',{staticClass:"form-group",on:{"click":toggleFocus}},[_c('label',{attrs:{"for":"session-template-notes"}},[_c('span',{staticClass:"label"},[_v(_s(label))])]),(this.disableMobileView)?[_c('editor',{ref:"notesInput",attrs:{"init":{selector: 'textarea.tinymce',
setup: function(editor) {
editor.on('init', function() {
editor.execCommand('fontName', false, 'Montserrat, sans-serif')
editor.selection.select(editor.getBody(), true)
editor.selection.collapse(false)
});
},
menubar: false,
elementpath: false,
branding: false,
browser_spellcheck:true,
contextmenu: false,
plugins: 'emoticons table insertdatetime link lists',
toolbar: 'insert | undo redo |  formatselect | bold italic forecolor backcolor' +
'  | alignleft aligncenter alignright alignjustify | ' +
' bullist numlist outdent indent table | removeformat | insertdatetime | emoticons | link | help',
table_default_styles: {
width: '100%', 'table-layout': 'fixed',
},
paste_data_images: true,
},"spellcheck":true},on:{"input":updateNotes},model:{value:(value),callback:function ($$v) {value=$$v},expression:"value"}})]:[_c('editor',{ref:"notesInput",attrs:{"init":{selector: 'textarea.tinymce',
setup: function(editor) {
editor.on('init', function() {
editor.execCommand('fontName', false, 'Montserrat, sans-serif')
editor.selection.select(editor.getBody(), true)
editor.selection.collapse(false)
});
},
mobile: {
theme: 'mobile',
plugins: 'table',
contextmenu: false,
toolbar: 'insert | undo redo |  formatselect | bold italic forecolor backcolor' +
'  | alignleft aligncenter alignright alignjustify | ' +
' bullist numlist outdent indent table | removeformat | help',
},
menubar: false,
elementpath: false,
branding: false,
browser_spellcheck:true,
contextmenu: false,
plugins: 'table',
toolbar: 'insert | undo redo |  formatselect | bold italic forecolor backcolor' +
'  | alignleft aligncenter alignright alignjustify | ' +
' bullist numlist outdent indent table | removeformat | help',
table_default_styles: {
width: '100%', 'table-layout': 'fixed',
},
paste_data_images: true,
},"spellcheck":true},on:{"input":updateNotes},model:{value:(value),callback:function ($$v) {value=$$v},expression:"value"}})]],2)}},
      staticRenderFns: []
    };
  