import Vue from 'vue/dist/vue.esm'
import template from './datetime_picker_template.slim'
import moment from 'moment'

Vue.component('datetime-picker', {
  mixins: [template],
  props: {
    initial_value: String,
    label_text: String,
    errors: Array,
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { value: this.initial_value }
  },
  methods: {
    updateDatetime(value) {
      let date = moment(value).format('MMMM Do YYYY')
      this.$emit('input', date)
    },
  },
})
