import Vue from 'vue/dist/vue.esm'
import template from './tracking_panel_item_template.slim'

Vue.component('tracking-panel-item', {
  mixins: [template],
  props: [
    'item',
    'get_item_title',
    'penultimate_score_class',
    'get_score',
    'update_score',
    'add_note',
    'on_press_add_student_note',
    'score_band'
  ],
  methods: {
    isSelected(value) {
      const scoreObject = this.get_score()
      return scoreObject && scoreObject.score === value
    },
  },
})
