import Vue from 'vue/dist/vue.esm'
import template from './video_player_template.slim'
import videojs from 'video.js'

Vue.component('video-player', {
  props: ['error_class', 'src'],
  data() {
    return {
      videoPlayer: {},
    }
  },
  mixins: [template],
  mounted() {
    this.videoPlayer = videojs('video-player', {
      fluid: true,
      techOrder: ['html5'],
    })
  },
  beforeDestroy() {
    this.videoPlayer.dispose()
  },
})
