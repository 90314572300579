import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../../../../../event-bus.js'
import template from './session_card_template.slim'

Vue.component('session-card', {
  mixins: [template],
  props: ['session'],
  data() {
    return {
      individual: this.session.students_count === 1,
      draft: this.session.draft,
      pluralizeObjective:
        this.session.objectives_count === 1 ? ' Objective: ' : ' Objectives: ',
    }
  },
  methods: {
    openClonedSession(session) {
      EventBus.$emit('open-cloned-session-modal', session)
    },
    goToDetail() {
      window.location = this.session.detail_path
    },
  },
})
