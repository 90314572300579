import Vue from 'vue/dist/vue.esm'
import './components/new_session_menu'
import './components/session_cards_panel'
import './components/objective_cards_panel'
import './components/favorited_sessions_modal'
import template from './dashboard_template.slim'
import { EventBus } from '../../event-bus.js'

Vue.component('dashboard', {
  mixins: [template],
  props: [
    'school_slug',
    'recent_sessions',
    'recent_objectives',
    'favorited_session_templates',
    'session_options',
    'view_all_sessions_path',
    'view_all_objectives_path',
    'current_staff_name',
    'is_mobile',
  ],
  data() {
    return {
      searchQuery: '',
      favoritesModalOpen: false,
    }
  },
  methods: {
    onSelectOption(option) {
      console.log(`User Selected Option: ` + option)
    },
    viewAllSessions() {
      window.location.href = this.view_all_sessions_path
    },
    viewAllObjectives() {
      window.location.href = this.view_all_objectives_path
    },
    openFavoritesModal() {
      if (this.is_mobile) {
        this.favoritesModalOpen = true
      }
    },
    closeFavoritesModal() {
      this.favoritesModalOpen = false
    },
  },
  mounted() {
    EventBus.$on('view-by-resource-changed', this.onSelectOption)

    EventBus.$on('close-favorites-modal', this.closeFavoritesModal)

    this.openFavoritesModal()
  },
})
