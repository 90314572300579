import Vue from 'vue/dist/vue.esm';
import template from './district_teachers_with_feedback_report_template';
import moment from 'moment';
import { getWidth } from '../commonChartFunctions';

Vue.component('district_teachers_with_feedback_report', {
  mixins: [template],
  props: ['teachers_with_feedback_percentages', 'school_year_label', 'year_info', 'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        xaxis: {
          categories: [],
          labels: {
            trim: true,
            rotate: -55,
            style: {
              fontSize: '10px'
            }
          }
        },
        yaxis: {
          title: {
            text: '% of Staff with Feedback Sessions'
          }
        },
        legend: {
          position: 'bottom'
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}%`;
            }
          }
        },
        dataLabels: {
          enabled: false
        }
      },
      series: []
    };
  },
  mounted() {
    this.updateChart();
  },
  watch: {
    teachers_with_feedback_percentages: {
      handler: 'updateChart',
      deep: true
    }
  },
  computed: {
    chartWidth() {
      return getWidth(this.series[0]?.data.length || 0);
    },
    isCurrentYear() {
      return this.year_info.is_current_school_year;
    },
    currentDate() {
      return moment().format('MMMM D, YYYY');
    }
  },
  methods: {
    updateChart() {
      if (!this.teachers_with_feedback_percentages || Object.keys(this.teachers_with_feedback_percentages).length === 0) {
        console.error("No teachers_with_feedback_percentages data available");
        return;
      }

      const sortedData = Object.entries(this.teachers_with_feedback_percentages)
        .map(([school, percentage]) => {
          return { school, percentage: percentage || 0 };
        })
        .sort((a, b) => b.percentage - a.percentage);

      const sortedSchoolNames = sortedData.map(data => data.school);
      const sortedFeedbackPercentages = sortedData.map(data => data.percentage);

      this.chartOptions.xaxis.categories = sortedSchoolNames;

      this.series = [{
        name: 'Teachers with Feedback',
        data: sortedFeedbackPercentages
      }];

      this.$refs.apexChart.updateOptions(this.chartOptions);
      this.$refs.apexChart.updateSeries(this.series);
    }
  }
});
