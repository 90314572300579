import Vue from 'vue/dist/vue.esm'
import template from './objective_card_template.slim'

Vue.component('objective-card', {
  mixins: [template],
  props: ['objective', 'selected', 'update_selected'],
  data() {
    return {
      isSelected: this.selected.includes(this.objective),
    }
  },
  methods: {
    goToDetail(e) {
      if (e.target !== this.$refs.selectObjective) {
        window.location = this.objective.detail_path
      }
    },
  },
})
