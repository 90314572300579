import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../event-bus.js'
import axios from 'axios'
import template from './new_session_modal_template.slim'
import moment from 'moment'
import _ from 'lodash'

const newSession = defaultAssessor => {
  return {
    kind: 'Observation',
    scheduled_at: moment(),
    session_name: null,
    assessment_template: null,
    assessors: [defaultAssessor],
    classroom: null,
    learners: null,
    objectives: null,
    errors: {},
  }
}

Vue.component('new-session-modal', {
  mixins: [template],
  props: ['session_options', 'current_staff_name'],
  data() {
    return {
      learnersOptions: [],
      classroomLearners: [],
      defaultAssessor: this.session_options.current_staff,
      addWholeClassDisabled: true,
      newSession: newSession(this.session_options.current_staff),
      removeTemplateLearners: true,
      shouldConfirm: false,
      visible: false,
      showNotes: false,
    }
  },
  watch: {
    'newSession.kind': ['doConfirm'],
    'newSession.scheduled_at': ['doConfirm'],
    'newSession.session_name': ['doConfirm'],
    'newSession.assessment_template': ['doConfirm'],
    'newSession.assessors': ['doConfirm'],
    'newSession.classroom': ['doConfirm', 'fetchLearners'],
    'newSession.learners': ['doConfirm', 'updateAddWholeClassDisabled', 'ensureArray'],
    'newSession.objectives': ['doConfirm'],
  },
  methods: {
    doConfirm() {
      this.shouldConfirm = true
    },
    doNotConfirm() {
      this.shouldConfirm = false
    },
    openSessionModal(sessionTemplate) {
      this.removeTemplateLearners = false
      if (sessionTemplate) {
        this.newSession = Object.assign({}, this.newSession, {
          kind: sessionTemplate.method,
          session_name: sessionTemplate.title,
          session_template_id: sessionTemplate.id,
          learners: sessionTemplate.learners,
          assessors: sessionTemplate.assessors,
          objectives: sessionTemplate.objectives,
          classroom: sessionTemplate.classroom,
          assessment_template: sessionTemplate.selected_assessment_template,
          notes: sessionTemplate.notes || null,
        })
      }
      if (sessionTemplate && sessionTemplate.shared) {
        this.newSession = Object.assign({}, this.newSession, {
          assessors: [this.session_options.current_staff],
        })
      }
      this.$refs.newSessionModal.open()
    },
    openClonedSessionModal(session) {
      this.removeTemplateLearners = false
      this.newSession = Object.assign(
        {},
        this.newSession,
        session.clone_options
      )
      if (session.method === 'Observation') {
        window.location.href = `/go/${
          this.$schoolSlug
        }/v2/dashboard/clone_session/${session.id}`
      } else {
        this.$refs.newSessionModal.open()
      }
    },
    openObjectivesSessionModal(objectives) {
      this.removeTemplateLearners = false
      this.newSession = Object.assign({}, this.newSession, {
        kind: 'Observation',
        objectives: _.map(objectives, objective => objective.as_option),
      })
      this.$refs.newSessionModal.open()
    },
    changeKind(newKind) {
      this.newSession.kind = newKind
    },
    isObservation() {
      return this.newSession.kind === 'Observation'
    },
    isIndividual() {
      return this.newSession.learners && this.newSession.learners.length === 1
    },
    fetchLearners(input) {
      if (input) {
        axios
          .get(
            `/go/${
              this.$schoolSlug
            }/v2/session_templates/classroom_learners?classroom_id=${
              input.value
            }`
          )
          .then(response => {
            this.learnersOptions = response.data

          })
          .catch(function(err) {
            console.log(err)
          })
      } else {
        this.learnersOptions = []
      }
      if (this.removeTemplateLearners) {
        this.newSession.learners = []
      }
      this.removeTemplateLearners = true
      this.updateAddWholeClassDisabled()
    },
    addWholeClass() {
      this.newSession.learners = this.learnersOptions
    },
    updateAddWholeClassDisabled() {
      this.addWholeClassDisabled =
        !this.$usesGroupObservations() ||
        !this.newSession.classroom ||
        this.newSession.learners === this.learnersOptions
    },
    ensureArray() {
      if (!Array.isArray(this.newSession.learners)) {
        this.newSession.learners = [this.newSession.learners]
      }
    },
    closeNewSession() {
      this.$refs.newSessionModal.close()
      this.resetForm()
    },
    onClose() {
      this.resetForm()
    },
    resetForm() {
      this.newSession = newSession()
      this.newSession.assessors = [this.defaultAssessor]
      this.learnersOptions = []
    },
    launchNewSession(kind) {
      const resource = this.sessionKind()
      axios
        .post(`/go/${this.$schoolSlug}/v2/${resource}s`, {
          [resource]: this.sessionParams(kind),
        })
        .then(response => {
          window.location = response.data.path
        })
        .catch(error => {
          this.newSession.errors = error.response.data
        })
    },
    sessionKind() {
      let kind = this.newSession.kind.toLowerCase()
      if (kind === 'observation' && this.isIndividual()) {
        kind = 'individual'
      }
      return kind + '_session'
    },
    sessionParams(kind) {
      const session = this.newSession
      let sharedParams = {
        classroom_id: session.classroom ? session.classroom.value : null,
        staff_ids: session.assessors
          ? session.assessors.map(a => a.value)
          : null,
        student_ids: session.learners
          ? session.learners.map(l => l.value)
          : null,
        notes: session.notes,
      }
      if (this.isObservation()) {
        return Object.assign(sharedParams, {
          session_name: session.session_name,
          scheduled_at: session.scheduled_at,
          session_template_id: session.session_template_id,
          assessment_template_id: session.assessment_template
            ? session.assessment_template.value
            : null,
          objective_ids: session.objectives
            ? session.objectives.map(o => o.value)
            : null,
          assessee_id: sharedParams.student_ids && sharedParams.student_ids[0],
        })
      } else {
        return Object.assign(sharedParams, {
          taken_on: session.scheduled_at,
          assessment_template_id: session.assessment_template
            ? session.assessment_template.value
            : null,
          kind: kind,
        })
      }
    },
    toggleNotes() {
      this.showNotes = !this.showNotes
    },
  },
  mounted() {
    EventBus.$on('open-new-session-modal', sessionTemplate => {
      this.openSessionModal(sessionTemplate)
    })
    EventBus.$on('open-cloned-session-modal', session =>
      this.openClonedSessionModal(session)
    )
    EventBus.$on(
      'open-objectives-session-modal',
      this.openObjectivesSessionModal
    )
    EventBus.$on('modal-closing', () => {
      this.visible = false
      this.doNotConfirm()
    })
    EventBus.$on('modal-opening', () => {
      this.visible = true
      setTimeout(this.doNotConfirm, 100)
    })
    EventBus.$on('update-session-notes', value => {
      this.newSession.notes = value
    })
  },
})
