import axios from 'axios'

const fetchLearners = (input, component) => {
  if (input) {
    axios
      .get(
        `/go/${
          component.school_slug
        }/v2/session_templates/classroom_learners?classroom_id=${input.value}`
      )
      .then(response => {
        component.learnersOptions = response.data
        component.updateAddWholeClassDisabled()
      })
      .catch(function(err) {
        console.log(err)
      })
  } else {
    component.learnersOptions = []
    component.updateAddWholeClassDisabled()
  }
}

const templateParams = template => ({
  kind: template.kind,
  title: template.title,
  classroom_id: template.classroom ? template.classroom.value : null,
  assessment_template_id: template.assessment_template
    ? template.assessment_template.value
    : null,
  assessor_ids: template.assessors
    ? template.assessors.map(a => a.value)
    : null,
  learner_ids: template.learners ? template.learners.map(l => l.value) : null,
  objective_ids: template.objectives
    ? template.objectives.map(o => o.value)
    : null,
  notes: template.notes,
})

export const sessionTemplateFormable = {
  // TODO: pull out shared functionality in session_template/index.js and
  // session_templates/components/new_session_template/index.js
  data() {
    return {
      submitting: false,
    }
  },
  watch: {
    'sessionTemplateForm.classroom': 'fetchLearners',
    'sessionTemplateForm.learners': ['updateAddWholeClassDisabled', 'ensureArray'],
  },
  computed: {
    submitDisabled() {
      return !this.shouldConfirmClose()
    },
  },
  methods: {
    addWholeClass() {
      this.sessionTemplateForm.learners = this.learnersOptions
    },
    ensureArray() {
      if (!Array.isArray(this.sessionTemplateForm.learners)) {
        this.sessionTemplateForm.learners = [this.sessionTemplateForm.learners]
      }
    },
    shouldConfirmClose() {
      return !(
        this.submitting ||
        _.isEqual(this.sessionTemplateForm, this.originalTemplate)
      )
    },
    toggleSubmitting(request) {
      this.submitting = !this.submitting
    },
    updateAddWholeClassDisabled() {
      const { classroom, learners } = this.sessionTemplateForm

      this.addWholeClassDisabled =
        !this.$usesGroupObservations() ||
        !classroom || learners.length === this.learnersOptions.length
    },
  },
}

export default {
  fetchLearners,
  templateParams,
}
