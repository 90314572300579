import Vue from 'vue/dist/vue.esm'
import template from './dropdown_menu_template.slim'

Vue.component('dropdown-menu', {
  props: ['menuId', 'buttonClasses'],
  data: function() {
    return {
      isActive: false,
    }
  },
  mixins: [template],
  methods: {
    close() {
      this.isActive = !this.isActive
    },
    onToggleMenu() {
      this.isActive = !this.isActive
    },
  },
})
