import Vue from 'vue/dist/vue.esm'
import template from './session_objectives_template.slim'
import { EventBus } from '../../../../event-bus.js'

Vue.component('session-objectives', {
  mixins: [template],
  props: ['objectives', 'objectiveOptions'],
  data() {
    return {
      filterBy: '',
    }
  },
  methods: {
    clearInput() {
      this.filterBy = ''
    },
    determineSelected(id) {
      return this.objectives.slice().includes(id)
    },
    returnFiltered() {
      return this.objectiveOptions.filter(obj => {
        return obj.label.toLowerCase().includes(this.filterBy.toLowerCase())
      })
    },
    selectObjective(id) {
      EventBus.$emit('select-objective', id)
    },
  },
  mounted() {},
})
