import Vue from 'vue/dist/vue.esm'
import './components/sidebar_item'
import template from './navigation_template.slim'

Vue.component('navigation', {
  mixins: [template],
  props: ['current_user', 'show_sidebar_menu'],
  data() {
    return {
      showSidebar: false,
      showProfileMenu: false,
    }
  },
  methods: {
    onToggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    onToggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu
    },
  },
  mounted() {
    $(this.$el)
      .find('.alerts .dropdown-toggle')
      .on('click', event => {
        event.stopPropagation()
        var $alerts = $('.dropdown-content#alerts-container')
        $alerts.toggle()
      })
    $(this.$el)
      .find('.drawer')
      .on('click', event => {
        event.stopPropagation()
        var $el = $(event.currentTarget)
        var $alertItem = $el.closest('.nav-alert-item')
        var $content = $alertItem.find('.drawer-content')
        var $siblings = $alertItem.siblings().find('.drawer-content')
        var $toggles = $alertItem.find('.drawer-toggle')
        $siblings.slideUp()
        $content.slideToggle()
        $toggles.each(function(_index, toggle) {
          var $toggle = $(toggle)
          var textSwap = $toggle.data('text-swap')

          if (typeof textSwap !== undefined) {
            var originalText = $toggle.text()
            $toggle.data('text-swap', originalText)
            $toggle.text(textSwap)
          }
        })
      })
  },
})
