import Vue from 'vue/dist/vue.esm'
import template from './launch_header_template.slim'
import { EventBus } from '../../../../event-bus.js'

Vue.component('launch-header', {
  mixins: [template],
  props: ['templateTitle', 'schoolSlug', 'previewOpen'],
  data() {
    return {
      previewLastClosed: null,
    }
  },
  methods: {
    togglePreview() {
      EventBus.$emit('toggle-preview')
    },
    close() {
      if (this.previewOpen) {
        this.togglePreview()
        this.previewLastClosed = Date.now()
      } else {
        if (Date.now() - this.previewLastClosed > 500) {
          // this prevents accidental closing of launch session page after closing preview
          let confirm = window.confirm(
            "Are you sure you'd like to exit? Progress will be lost."
          )

          if (confirm) {
            window.location.pathname = `/go/${this.schoolSlug}/v2/dashboard`
          }
        }
      }
    },
  },
  mounted() {},
})
