import Vue from 'vue/dist/vue.esm'
import template from './mobile_track_group_template.slim'
import axios from 'axios'
import './components/track_header'
import './components/tracking_grid'
import './components/track_navigation_list'

Vue.component('mobile-track-group', {
  mixins: [template],
  props: [
    'school_slug',
    'session',
    'learners_options',
    'objectives_options',
    'penultimate_score_classes',
    'score_band'
  ],
  data() {
    return {
      sessionState: this.session,
      sessionEdits: this.initializeSessionEdits(this.session),
      scoringBy: 'objectives',
      scoreAllParams: {
        recipients: null,
        subject: null,
        scores: [],
        subject_id: null,
      },
      sessionNote: this.session.notes,
      studentNote: null,
      selectedObjective: {},
      selectedStudent: {},
    }
  },

  computed: {
    hasNotes() {
      return (
        this.sessionState.notes !== '' &&
        this.sessionState.notes !== '<p></p>' &&
        this.sessionState.notes !== null
      )
    },
  },
  methods: {
    initializeSessionEdits(session) {
      return {
        session_name: session.session_name,
        learners: _.filter(this.learners_options, option =>
          _.includes(session.student_ids, option.value.toString())
        ),
        objectives: _.filter(this.objectives_options, option =>
          _.includes(session.objective_ids, option.value.toString())
        ),
        errors: {},
      }
    },
    orderedStudents() {
      return _.sortBy(this.sessionState.students, 'full_name')
    },
    openScoreAllLearners(objective) {
      this.scoreAllParams = {
        recipients: 'All Students',
        subject: objective.title,
        scores: _.filter(this.sessionState.mastery_scores, {
          mastery_objective_id: objective.id,
        }),
        subject_id: objective.id,
      }
      this.$refs.scoreAllPanel.toggleVisible()
    },
    openScoreAllObjectives(student) {
      this.scoreAllParams = {
        recipients: 'All Objectives',
        subject: student.full_name,
        scores: _.filter(this.sessionState.mastery_scores, {
          student_id: student.id,
        }),
        subject_id: student.id,
      }
      this.$refs.scoreAllPanel.toggleVisible()
    },
    openObjectiveDetailsModal(objective) {
      this.selectedObjective = objective
      this.$refs.objectiveDetailsPanel.toggleVisible()
    },
    openStudentDetailsModal(student) {
      this.selectedStudent = student
      this.$refs.studentDetailsPanel.toggleVisible()
    },
    openSessionNoteModal() {
      this.$refs.sessionNotePanel.toggleVisible()
    },
    onPressCancelScoreAll() {
      this.$refs.scoreAllPanel.toggleVisible()
    },
    onPressSaveAllScores(score) {
      if (this.scoringBy === 'objectives') {
        this.scoreAllStudentsForObjective(score)
      } else if (this.scoringBy === 'students') {
        this.scoreAllObjectivesForStudent(score)
      }
    },
    onPressDeleteSession() {
      if (confirm('Are you sure you want to delete this session?')) {
        axios
          .delete(
            `/go/${this.school_slug}/v2/observation_sessions/${this.session.id}`
          )
          .then(response => {
            window.location = response.data;
          })
          .catch(err => {
            this.sessionEdits.errors = `Unable to delete session due to: ${err}`;
          })
      }
    },
    scoreAllStudentsForObjective(score) {
      const objective = _.find(this.sessionState.objectives, {
        id: this.scoreAllParams.subject_id,
      })
      const masteryScores = _.map(this.sessionState.students, student =>
        this.getScoreParams({ student, objective, score })
      )
      this.updateScores(masteryScores)
      this.$refs.scoreAllPanel.toggleVisible()
    },
    scoreAllObjectivesForStudent(score) {
      const student = _.find(this.sessionState.students, {
        id: this.scoreAllParams.subject_id,
      })
      const masteryScores = _.map(this.sessionState.objectives, objective =>
        this.getScoreParams({ student, objective, score })
      )
      this.updateScores(masteryScores)
      this.$refs.scoreAllPanel.toggleVisible()
    },
    onSelectNavigationItem({ item, grid }) {
      this.$refs[grid].goToPanel(item)
      this.$refs.changeScoreByMenu.toggleVisible()
    },
    openChangeScoreByMenu(type) {
      this.scoringBy = type
      this.$refs.changeScoreByMenu.toggleVisible()
    },
    getScore({ student, objective }) {
      return _.find(this.sessionState.mastery_scores, {
        student_id: student.id,
        mastery_objective_id: objective.id,
      })
    },
    getScoreParams({ student, objective, score }) {
      return {
        mastery_score: {
          student_id: student.id,
          mastery_objective_id: objective.id,
          score,
        },
      }
    },
    updateScores(scores) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/observation_sessions/${
            this.session.id
          }/score`,
          {
            scores,
          }
        )
        .then(response => {
          const {
            mastery_scores,
            session_students_recent_scores,
          } = response.data
          this.sessionState.mastery_scores = this.updatedMasteryScores(
            mastery_scores
          )
          this.sessionState.session_students_recent_scores = session_students_recent_scores
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    updatedMasteryScores(newMasteryScores) {
      return _.unionWith(
        newMasteryScores,
        this.sessionState.mastery_scores,
        (a, b) => {
          return (
            a.student_id === b.student_id &&
            a.mastery_objective_id === b.mastery_objective_id
          )
        }
      )
    },
    onPressCancelSessionNote() {
      this.sessionNote = this.sessionState.notes
      this.$refs.sessionNotePanel.toggleVisible()
    },
    onPressSaveSessionNote() {
      axios
        .patch(
          `/go/${this.school_slug}/v2/observation_sessions/${
            this.session.id
          }/update_notes`,
          {
            notes: this.sessionNote,
          }
        )
        .then(response => {
          this.sessionState.notes = this.sessionNote
          this.$refs.sessionNotePanel.toggleVisible()
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    recentScoresFor(student) {
      const { session_students_recent_scores } = this.sessionState
      return _.get(_.find(session_students_recent_scores, {student_id: student.id }), 'recent_scores')
    },
    openStudentNoteModal(student) {
      this.selectedStudent = student
      this.studentNote = this.findOrInitializeStudentNote()
      this.$refs.studentNotePanel.toggleVisible()
    },
    findOrInitializeStudentNote() {
      let note = ''
      const { recent_notes_as_json } = this.selectedStudent
      const { id } = this.sessionState
      const existingNote = _.find(recent_notes_as_json, { session_id: id })
      if (existingNote) {
        note = existingNote.note
      }
      return note
    },
    updateStudentNote(input) {
      this.studentNote = input.target.value
    },
    onPressSaveStudentNote() {
      axios
        .patch(`/go/${this.school_slug}/v2/mastery_notes/make_note`, {
          note: this.studentNote,
          student_id: this.selectedStudent.id,
          session_id: this.sessionState.id,
        })
        .then(response => {
          const newNote = response.data
          const oldStudent = _.find(this.sessionState.students, {
            id: this.selectedStudent.id,
          })
          const updatedNotes = this.updatedRecentNotes(
            newNote,
            oldStudent.recent_notes_as_json
          )
          const updatedStudent = _.merge(
            {},
            {
              ...oldStudent,
              recent_notes_as_json: updatedNotes,
            }
          )
          const studentIndex = _.indexOf(this.sessionState.students, oldStudent)
          const updatedStudents = [
            ...this.sessionState.students.slice(0, studentIndex),
            updatedStudent,
            ...this.sessionState.students.slice(studentIndex + 1),
          ]
          this.sessionState.students = updatedStudents
          this.$refs.studentNotePanel.toggleVisible()
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    updatedRecentNotes(newNote, oldNotes) {
      if (_.isEmpty(newNote.note)) {
        return _.filter(oldNotes, note => note.id !== newNote.id)
      } else {
        return _.unionWith([newNote], oldNotes, (a, b) => a.id === b.id)
      }
    },
    onPressCancelStudentNote() {
      this.$refs.studentNotePanel.toggleVisible()
    },
    openSessionEditPanel() {
      this.$refs.sessionEditPanel.toggleVisible()
    },
    onPressCancelEditSession() {
      this.sessionEdits = this.initializeSessionEdits(this.sessionState)
      this.$refs.sessionEditPanel.toggleVisible()
    },
    onPressSaveSession() {
      axios
        .patch(
          `/go/${this.school_slug}/v2/observation_sessions/${this.session.id}`,
          {
            observation_session: this.observationSessionParams(),
          }
        )
        .then(response => {
          const updatedSession = response.data
          this.sessionState = updatedSession
          this.sessionEdits = this.initializeSessionEdits(updatedSession)
          this.selectedStudent = {}
          this.selectedObjective = {}
          this.$refs.sessionEditPanel.toggleVisible()
        })
        .catch(error => {
          this.sessionEdits.errors = error.response.data
        })
    },
    observationSessionParams() {
      return {
        session_name: this.sessionEdits.session_name,
        student_ids: _.map(this.sessionEdits.learners, l => l.value),
        objective_ids: _.map(this.sessionEdits.objectives, o => o.value),
      }
    },
  },
})
