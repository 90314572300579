import Vue from 'vue/dist/vue.esm'
import template from './launch_footer_template.slim'
import { EventBus } from '../../../../event-bus.js'
import _ from 'lodash'

Vue.component('launch-footer', {
  mixins: [template],
  props: ['step', 'form', 'previewOpen', 'inEditMode'],
  data() {
    return {}
  },
  computed: {
    readyToLaunch() {
      return (
        this.form.name &&
        this.form.assessors.length > 0 &&
        this.form.learners.length > 0 &&
        this.form.objectives.length > 0
      )
    },
  },
  methods: {
    next() {
      EventBus.$emit('next-step')
    },
    previous() {
      EventBus.$emit('previous-step')
    },
    launchEditMode() {
      EventBus.$emit('set-edit-launch-mode')
    },
    launchSession: _.debounce(
      () => {
        EventBus.$emit('launch-session')
      },
      3000,
      {
        leading: true,
        trailing: false,
      }
    ),
  },
  mounted() {},
})
