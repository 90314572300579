import Vue from 'vue/dist/vue.esm'
import template from './district_session_types_by_school_report_template'
import moment from 'moment'
import { getUrlWithSlug, getWidth, seriesData, mergeCountsBar } from '../commonChartFunctions';

Vue.component('district_session_types_by_school_report', {
  mixins: [template],
  props: ['mastery_feedback_sessions', 'narrative_feedback_sessions', 'self_reflection_sessions', 'peer_visit_sessions', 'session_data',
    'school_year_label', 'year_info', 'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      series: [{
        name: 'Mastery Feedback Sessions',
        data: seriesData(mergeCountsBar(this.mastery_feedback_sessions, this.startDate, this.endDate))
      }, {
        name: 'Narrative Feedback Sessions',
        data: seriesData(mergeCountsBar(this.narrative_feedback_sessions, this.startDate, this.endDate))
      }, {
        name: 'Self Reflection Sessions',
        data: seriesData(mergeCountsBar(this.self_reflection_sessions, this.startDate, this.endDate))
      },
      ...(this.anyPeerVisitSessions()
        ? [{
            name: 'Peer Visit Sessions',
            data: seriesData(mergeCountsBar(this.peer_visit_sessions, this.startDate, this.endDate))
          }] 
        : [])  
      ],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'bar',
          stacked: true,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const schoolName = config.w.config.series[0].data[config.dataPointIndex].x;
              const urlWithSlug = getUrlWithSlug(this.year_info.current_schools_year, schoolName);
              const redirectUrl = urlWithSlug + "/my_sessions";
              window.open(redirectUrl, '_blank');
            }
          }
        },
        yaxis: {
          title: {
            text: "Sessions",
            style: {
              color: '#949494'
            }
          },
          forceNiceScale: true
        },
        xaxis: {
          labels: {
            trim: true,
            rotate: -55,
          }
        },
        grid: {
          row: {
            colors: ['#FFFFFF', '#F3F3F3']
          },
          padding: {
            left: 50,
            bottom: 30
          },
        },
        colors:['#3FE6A4', '#008FFB', "#FF7878", "#00FF00"]
      }
    }
  },
  mounted() {
      this.wholeYearRangeClicked();
  },
  computed: {
    chartWidth: function() {
      return getWidth(this.series[0].data.length);
    },
    isCurrentYear: function() {
      return this.year_info.is_current_school_year
    },
    currentDate: function() {
      return moment().format('MMMM D, YYYY');
    },
  },
  methods: {
    sevenDayRangeClicked: function() {
      this.setDayRange(7);
    },
    thirtyDayRangeClicked: function() {
      this.setDayRange(30);
    },
    wholeYearRangeClicked: function() {
      this.startDate = this.year_info.school_start_date;
      this.endDate = moment().endOf('day');
      this.updateChart();
    },
    setDayRange: function(day) {
      this.startDate = moment().subtract(day + 1, "days");
      this.endDate = moment().subtract(1, "days");
      this.updateChart();
    },
    updateChart: function() {
      let series = [{
        data: seriesData(mergeCountsBar(this.mastery_feedback_sessions, this.startDate, this.endDate))
      }, {
        data: seriesData(mergeCountsBar(this.narrative_feedback_sessions, this.startDate, this.endDate))
      }, {
        data: seriesData(mergeCountsBar(this.self_reflection_sessions, this.startDate, this.endDate))
      },
      ...(this.anyPeerVisitSessions()
        ? [{
            data: seriesData(mergeCountsBar(this.peer_visit_sessions, this.startDate, this.endDate))
          }] 
        : [])
      ];
      let sums = {};
      series.forEach(item => {
        item.data.forEach(({ x, y }) => {
          sums[x] = (sums[x] || 0) + y;
        });
      });

      series.forEach(item => {
        item.data.sort((a, b) => sums[b.x] - sums[a.x]);
      });
      this.$refs.perSchoolApexChart.updateSeries(series);
    },
    anyPeerVisitSessions: function() {
       let no_pr_visit_session = Object.values(this.peer_visit_sessions).every(
        value => Object.keys(value).length === 0
      );

      return !no_pr_visit_session;
    }
  }
});
