import Vue from 'vue/dist/vue.esm'
import template from './learners_multiselect_template.slim'
import withScrollToTop from '../../../mixins/with_scroll_to_top.js'

Vue.component('learners-multiselect', {
  mixins: [template, withScrollToTop],
  props: [
    'title',
    'value',
    'learners_options',
    'errors',
    'required',
    'add_whole_class_disabled',
    'add_whole_class',
    'add_whole_class_hidden',
  ],
  data() {
    return {
      usesGroupObservations: this.$usesGroupObservations(),
      hasFocus: false,
    }
  },
  methods: {
    updateLearners(value) {
      this.$emit('input', value)
    },
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      } else {
        this.$refs.learnersSelect.$el.focus()
      }
      this.hasFocus = !this.hasFocus
    },
  },
})
