import Vue from 'vue/dist/vue.esm'
import template from './district_next_step_per_school_report_template'
import moment from 'moment'
import { getWidth, seriesData, mergeCountsBar } from '../commonChartFunctions';

Vue.component('district_next_step_per_school_report', {
  mixins: [template],
  props: ['created_next_steps', 'completed_next_steps', 'incomplete_next_steps', 'school_year_label', 'year_info',
    'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      series: [{
        name: 'Completed',
        data: seriesData(mergeCountsBar(this.completed_next_steps, this.startDate, this.endDate))
      }, {
        name: 'Open',
        data: seriesData(mergeCountsBar(this.incomplete_next_steps, this.startDate, this.endDate))
      }],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'bar',
          stacked: true,
        },
        yaxis: {
          title: {
            text: "District Next Steps",
            style: {
              color: '#949494'
            }
          },
          forceNiceScale: true
        },
        xaxis: {
          labels: {
            trim: true,
            rotate: -55,
          }
        },
        grid: {
          row: {
            colors: ['#FFFFFF', '#F3F3F3']
          },
          padding: {
            left: 50,
            bottom: 30
          },
        },
        colors:['#3FE6A4', '#008FFB']
      }
    }
  },
  mounted() {
      this.wholeYearRangeClicked();
  },
  computed: {
    chartWidth: function() {
      return getWidth(this.series[0].data.length);
    },
    isCurrentYear: function() {
      return this.year_info.is_current_school_year
    },
    currentDate: function() {
      return moment().format('MMMM D, YYYY');
    },
  },
  methods: {
    sevenDayRangeClicked: function() {
      this.setDayRange(7);
    },
    thirtyDayRangeClicked: function() {
      this.setDayRange(30);
    },
    wholeYearRangeClicked: function() {
      this.startDate = this.year_info.school_start_date;
      this.endDate = moment().endOf('day');
      this.updateChart();
    },
    setDayRange: function(day) {
      this.startDate = moment().subtract(day + 1, "days");
      this.endDate = moment().subtract(1, "days");
      this.updateChart();
    },
    updateChart: function() {
      let series = [{
        data: seriesData(mergeCountsBar(this.completed_next_steps, this.startDate, this.endDate))
      }, {
        data: seriesData(mergeCountsBar(this.incomplete_next_steps, this.startDate, this.endDate))
      }];
      let sums = {};
      series.forEach(item => {
        item.data.forEach(({ x, y }) => {
          sums[x] = (sums[x] || 0) + y;
        });
      });

      series.forEach(item => {
        item.data.sort((a, b) => sums[b.x] - sums[a.x]);
      });
      this.$refs.perSchoolApexChart.updateSeries(series);
    }
  }
});
