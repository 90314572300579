import Vue from 'vue/dist/vue.esm'
import template from './modal_template.slim'
import { EventBus } from '../../event-bus.js'

Vue.component('modal', {
  props: {
    modal_visible: Boolean,
    should_confirm: Boolean,
    confirm_message: String,
    on_close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { isModalVisible: this.modal_visible }
  },
  mixins: [template],
  methods: {
    close() {
      if (
        !this.should_confirm ||
        (this.should_confirm && confirm(this.confirm_message))
      ) {
        this.on_close()
        this.isModalVisible = false
        EventBus.$emit('modal-closing')
      }
    },
    open() {
      this.isModalVisible = true
      EventBus.$emit('modal-opening')
    },
  },
  mounted() {
    EventBus.$on('close-modal', this.close)
  },
})
