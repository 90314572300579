import Vue from 'vue/dist/vue.esm'
import template from './checkbox_panel_template.slim'

Vue.component('checkbox-panel', {
  data() {
    return { active: false }
  },
  props: ['title'],
  mixins: [template],
  mounted: function() {
    this.$parent.addPanel(this)
  },
})
