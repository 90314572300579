import Vue from 'vue/dist/vue.esm'
import template from './assessment_template_select_template.slim'

Vue.component('assessment-template-select', {
  mixins: [template],
  props: ['value', 'show_label', 'assessment_template_options', 'errors'],
  data() {
    return {
      hasFocus: false,
    }
  },
  methods: {
    updateAssessmentTemplate(value) {
      this.$emit('input', value)
    },
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      } else {
        this.$refs.assessmentTemplateSelect.$el.focus()
      }
      this.hasFocus = !this.hasFocus
    },
  },
})
