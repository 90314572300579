import Vue from 'vue/dist/vue.esm'
import template from './student_details_template.slim'

Vue.component('student-details', {
  mixins: [template],
  props: [
    'student',
    'recent_scores',
    'objective',
    'score_band'
  ],
})
