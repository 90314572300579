import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../../../event-bus.js'
import template from './favorited_sessions_modal_template.slim'

Vue.component('favorited-sessions-modal', {
  mixins: [template],
  props: ['favorited_templates'],
  methods: {
    openFavoritedSession(sessionTemplate) {
      if (this.$isMobile) {
        const sessionId = sessionTemplate ? sessionTemplate.id : 'new'
        window.location.pathname = `/go/${
          this.$schoolSlug
        }/v2/dashboard/launch_session/${sessionId}`
      }
    },
    closeModal() {
      EventBus.$emit('close-favorites-modal')
    },
  },
})
