import Vue from 'vue/dist/vue.esm'
import template from './medium_panel_template.slim'

Vue.component('medium-panel', {
  props: ['medium', 'on_press_cancel_medium', 'save_medium', 'delete_medium'],
  mixins: [template],
  data() {
    return {
      mediumId: this.medium.id,
      fileInputName: 'file-input-' + this.medium.id,
      mimeType: this.medium ? this.medium.mime_type : '',
      mediumUrl: this.medium ? this.medium.medium_url : '',
      ...this.initialState(),
    }
  },
  watch: {
    medium: function(newMedium) {
      this.mediumUrl = newMedium ? newMedium.medium_url : ''
      this.mimeType = newMedium ? newMedium.mime_type : ''
      this.mediumId = newMedium ? newMedium.id : null
      this.fileInputName = newMedium
        ? 'file-input-' + newMedium.id
        : 'file-input-null'
    },
  },
  methods: {
    initialState(url) {
      return {
        fileName: '',
        fetching: false,
        progress: '0%',
        progressClass: 'fetching',
        error: null,
      }
    },
    onSaveMediumSuccess(url, mime_type, medium_id) {
      Object.assign(this.$data, {
        mediumUrl: url,
        mimeType: mime_type,
        mediumId: medium_id,
        ...this.initialState(),
      })
    },
    onSaveMediumFailed(error) {
      this.fetching = false
      this.progress = '100%'
      this.error = error
      this.progressClass = 'error'
    },
    initDirectUploader() {
      $(this.$el)
        .find('input.file')
        .fileupload({
          replaceFileInput: false,
          add: (e, data) => {
            this.fetching = true
            this.fileName = data.files[0].name
            this.progressClass = 'fetching'
            this.mediumUrl = false
            this.error = null
            const options = {
              filename: data.files[0].name,
              _: Date.now,
            }
            $.getJSON('/resources/cache/presign', options, function(result) {
              data.formData = result.fields
              data.url = result.url
              data.paramName = 'file'
              data.submit()
            })
          },
          progress: (e, data) => {
            this.progress = `${parseInt((data.loaded / data.total) * 100, 10)}%`
          },
          done: (e, data) => {
            this.progressClass = 'saving'
            const mediumData = JSON.stringify({
              id: data.formData.key.match(/cache\/(.+)/)[1],
              storage: 'cache',
              metadata: {
                size: data.files[0].size,
                filename: data.files[0].name.match(/[^\/\\]+$/)[0],
                mime_type: data.files[0].type,
              },
            })
            this.save_medium(
              mediumData,
              this.mediumId,
              this.onSaveMediumSuccess,
              this.onSaveMediumFailed
            )
          },
          fail: (e, data) => {
            this.fetching = false
            this.progress = '0%'
            this.progressClass = 'error'
            this.mediumUrl = this.medium ? this.medium.medium_url : false
            this.error = data.response().errorThrown
          },
        })
    },
  },
  mounted() {
    this.initDirectUploader()
  },
})
