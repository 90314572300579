import Vue from 'vue/dist/vue.esm'
import template from './notes_wysiwyg_template.slim'
import { EventBus } from '../../../event-bus'

Vue.component('notes-wysiwyg', {
  mixins: [template],
  props: {
    initialValue: null,
    mobile: null,
    disableMobileView: null,
    event: null,
    label: {default: ''}
  },
  data() {
    return {
      hasFocus: false,
      value: '',
      watchInitialized: false,
    }
  },
  methods: {
    updateNotes() {
      const event = this.event || 'update-session-notes';
      this.$emit('input', this.$refs.notesInput.value)
      this.$emit('input', this.value)
      EventBus.$emit(event, this.value)
    },
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      }
      this.hasFocus = !this.hasFocus
    },
  },
  mounted() {
    if (this.initialValue) {
      this.value = this.initialValue
    }
  },
  watch: {
    initialValue(newInitialValue) {
      this.value = newInitialValue;
    },
  },
})
