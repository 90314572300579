import Vue from 'vue/dist/vue.esm'
import template from './dropdown_button_template.slim'
import { EventBus } from '../../event-bus.js'

Vue.component('dropdown-button', {
  props: ['items', 'on_select_option'],
  data: function() {
    return {
      isActive: false,
      options: this.parseItems(),
      activeOption: this.firstOption(),
    }
  },
  mixins: [template],
  methods: {
    parseItems() {
      return this.items.split(', ')
    },
    firstOption() {
      return this.parseItems()[0]
    },
    onToggleDropdown() {
      this.isActive = !this.isActive
    },
    onSelect(option) {
      this.activeOption = option
      EventBus.$emit(this.on_select_option, option)
    },
  },
})

//Usage: define a method 'onSelectOption' on the parent Vue instance, with a handler for each of the options.
