import Vue from 'vue/dist/vue.esm'
import template from './score_card_template.slim'

Vue.component('score-card', {
  props: [
    'objective',
    'narrative_feedback',
    'on_press_score',
    'score',
    'open_objective_details_modal',
    'open_objective_note_modal',
    'open_learner_details_modal',
    'past_objective_score',
    'open_checklist_items_modal',
    'score_band'
  ],
  computed: {
    hasNotes() {
      if (!this.score) return false

      return (
        this.score.notes !== '' &&
        this.score.notes !== ' ' &&
        this.score.notes !== '<p></p>' &&
        this.score.notes !== null
      )
    },
    lastScoreClass() {
      switch (this.past_objective_score?.score) {
        case 0:
          return 'no'
        case 1:
          return 'somewhat'
        case 2:
          return 'mostly'
        case 3:
          return 'yes'
        case 4:
          return 'exemplary'
        default:
          return 'no-score'
      }
    },
  },
  mixins: [template],
  methods: {
    getCheckedItemCount(objective) {
      return objective.checklist_items_list.filter(item => item.checked).length;
    },
    isSelected(value) {
      const scoreObject = this.score
      return scoreObject && scoreObject.score === value
    },
  },
})
