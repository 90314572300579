import Vue from 'vue/dist/vue.esm'
import template from './district_all_objectives_report_template.slim'
import moment from 'moment'

const LABELS = ["Not Yet", "Somewhat", "Mostly", "Yes", "Exemplary"];
const SERIES = [4, 3, 2, 1, 0, ""];
const scoreToLabel = ([score, _count]) => {
  return LABELS[score] || "No Score";
};

Vue.component('district_all_objectives_report', {
  mixins: [template],
  props: ['all_scores', 'school_year_label', 'year_info', 'report_date'],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: ["#2573D9", "#429028", "#65CE4C", "#E4C55E", "#E8875E", "#A5AFBF"]
        },
        legend: {
          markers: {
            fillColors: ["#2573D9", "#429028", "#65CE4C", "#E4C55E", "#E8875E", "#A5AFBF"]
          }
        }
      }
    }
  },
  mounted() {
      this.wholeYearRangeClicked();
  },
  computed: {
    isCurrentYear: function() {
      return this.year_info.is_current_school_year
    },
    currentDate: function() {
      return moment().format('MMMM D, YYYY');
    },
  },
  methods: {
    sortedData: function(range) {
      return SERIES.map((score) => {
        return [score, this.all_scores[range][score] || 0];
      })
    },
    chart: function() {
      return this.$refs.allScoresApexChart;
    },
    update: function(range) {
      const data = this.sortedData(range);
      const series = _.map(data, 1);
      const labels = _.map(data, scoreToLabel);

      this.chart().updateSeries(series);
      this.chart().updateOptions({labels});
    },
    sevenDayRangeClicked: function() {
      this.update("week");
    },
    thirtyDayRangeClicked: function() {
      this.update("month");
    },
    wholeYearRangeClicked: function() {
      this.update("year");
    },
  }
})
