import Vue from 'vue/dist/vue.esm'
import VueTruncate from 'vue-truncate-filter'
Vue.use(VueTruncate)
import template from './session_template_card_panel_template.slim'
import './components/session_template_card'

Vue.component('session-template-card-panel', {
  mixins: [template],
  props: ['session_templates', 'toggle_favorite'],
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    filteredSessionTemplates() {
      return this.session_templates.filter(sessionTemplate => {
        var cardText = []
        cardText.push(sessionTemplate.title)
        cardText.push(sessionTemplate.method)
        cardText.push(sessionTemplate.level)
        cardText.push(sessionTemplate.created_date)
        cardText.push(sessionTemplate.learner_list)
        cardText.push(sessionTemplate.objectives_list)
        return cardText
          .join()
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      })
    },
  },
})
