import Vue from 'vue/dist/vue.esm'
import template from './text_input_template.slim'

Vue.component('text-input', {
  mixins: [template],
  props: [
    'value',
    'required',
    'errors',
    'label_text',
    'id',
    'attrs',
    'css_classes',
    'readonly',
    'type',
  ],
  data() {
    return {
      input_attrs: {
        type: this.type || 'text',
        ref: 'textInput',
      },
    }
  },
  methods: {
    updateText() {
      this.$emit('input', this.$refs.textInput.value)
    },
  },
})
