import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../../../event-bus.js'
import template from './new_session_menu_template.slim'

Vue.component('new-session-menu', {
  mixins: [template],
  props: ['session_templates', 'is_mobile'],
  methods: {
    openFavoritedSession(sessionTemplate) {
      const sessionId = sessionTemplate ? sessionTemplate.id : 'new'
      window.location.pathname = `/go/${
        this.$schoolSlug
      }/v2/dashboard/launch_session/${sessionId}`
    },
  },
})
