import Vue from 'vue/dist/vue.esm'
import template from './learner_select_template.slim'
import withScrollToTop from '../../../mixins/with_scroll_to_top.js'

Vue.component('learner-select', {
  mixins: [template, withScrollToTop],
  props: ['value', 'learners_options', 'errors', 'required', 'placeholder_text', 'multi_select', 'changeAllOwnersCheckbox'],
  data() {
    return {
      hasFocus: false,
    }
  },
  methods: {
    updateLearner(value) {
      this.$emit('input', value)
      if(this.multi_select) {
        this.changeAllOwnersCheckbox();
      }
    },
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      } else {
        this.$refs.learnerSelect.$el.focus()
      }
      this.hasFocus = !this.hasFocus
    },
  },
})
