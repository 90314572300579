import Vue from 'vue/dist/vue.esm'
import template from './session_preview_template.slim'

Vue.component('session-preview', {
  mixins: [template],
  props: [
    'name',
    'date',
    'narrativeFeedback',
    'assessors',
    'assessorsOptions',
    'objectivesChosen',
    'objectiveOptions',
    'learners',
    'notes',
  ],
  data() {
    return {}
  },
  computed: {
    objectives: function() {
      return this.objectiveOptions.filter(obj => {
        return this.objectivesChosen.includes(obj.value)
      })
    },
    chosenAssessors: function() {
      return this.assessors.map(a => {
        return this.assessorsOptions
          .slice()
          .find(assessor => assessor.value === a).label
      })
    },
  },
  methods: {
    setNotesHTML() {
      if (this.notes) {
        this.$refs.templateNotes.innerHTML = this.notes
      }
    },
  },
  mounted() {
    this.setNotesHTML()
  },
})
