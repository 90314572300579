import Vue from 'vue/dist/vue.esm'
import template from './modal_overlay_template.slim'
import { EventBus } from '../../event-bus.js'

Vue.component('modal-overlay', {
  props: {
    modal_visible: Boolean,
  },
  data() {
    return { isModalVisible: this.modal_visible }
  },
  mixins: [template],
  methods: {
    close() {
      this.isModalVisible = false
      EventBus.$emit('modal-closing')
    },
    open() {
      this.isModalVisible = true
      EventBus.$emit('modal-opening')
    },
  },
})
