import Vue from 'vue/dist/vue.esm'
import template from './scoring_row_template.slim'

Vue.component('scoring-row', {
  mixins: [template],
  props: ['is_selected', 'update_score', 'score_band'],
  data() {
    let scoreBand = Number.parseInt(this.score_band);
    return {
      scoreMap: [
        scoreBand === 5 ? { display: 'exemplary', value: 4, class: 'exemplary' } : null,
        { display: 'yes', value: 3, class: 'yes' },
        { display: 'mostly', value: 2, class: 'mostly' },
        { display: 'somewhat', value: 1, class: 'somewhat' },
        scoreBand > 3 ? { display: 'not yet', value: 0, class: 'no' } : null
      ].filter(score => score !== null)
    }
  },
})
