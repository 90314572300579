import moment from 'moment'

export function getUrlWithSlug(year, schoolName) {
    const currentYear = year - 2000;
    const formattedSchoolName = parameterize(schoolName);
    const slug = formattedSchoolName + "-" + currentYear + "-" + (currentYear + 1);
    return  window.location.protocol + "//" + window.location.host + "/go/" + slug;
}

export function parameterize(str) {
    return str.toLowerCase()
        .replace(/[^a-zA-Z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '')
        .replace(/-{2,}/g, '-');
}

export function getWidth(schoolCount) {
    const width = schoolCount * 45;
    if(width < 450)
        return 450;
    else
        return width;
}

export function seriesData(data){
    return _.orderBy(_.map(data, (value, key) => ({x: key, y: value})), 'x');
}

export function seriesDataBar(data) {
    return _.orderBy(_.map(data, (value, key) => ({ x: key, y: value })), 'y', 'desc');
}

export function mergeCounts(session_data) {
    return _.reduce(session_data, (memo, school) => _.transform(school, (memo, value, key) => {
        memo[key] = (memo[key] || 0) + value;
    }, memo), {});
}

export function mergeCountsBar(session_data, startDate, endDate) {
    return _.transform(_.keys(session_data), (mergedData, school) => {
        mergedData[school] = _.reduce(session_data[school], (sum, val, key) => {
            if(moment(key).isBetween(startDate, endDate)) {
                return sum + val;
            }
            return sum;
        }, mergedData[school] || 0);
    }, {})
}
