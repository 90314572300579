const withScrollToTop = {
  data() {
    return {
      topPosition: 0,
    }
  },
  mounted() {
    this.topPosition = $(this.$el).position().top
  },
  methods: {
    scrollToTop() {
      const $target = $(this.$el).closest('.modal')
      const currentScroll = $target.scrollTop()
      if (currentScroll < this.topPosition) {
        $target.animate(
          {
            scrollTop: this.topPosition,
          },
          500
        )
      }
    },
  },
}

export default withScrollToTop
