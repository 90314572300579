import Vue from 'vue/dist/vue.esm'
import VueTruncate from 'vue-truncate-filter'
Vue.use(VueTruncate)
import _ from 'lodash'
import template from './score_card_panel_template.slim'
import './components/score_card'

Vue.component('score-card-panel', {
  mixins: [template],
  props: [
    'objectives',
    'narrative_feedback',
    'on_score_objective',
    'get_score',
    'open_objective_details_modal',
    'open_objective_note_modal',
    'open_learner_details_modal',
    'past_objective_scores',
    'open_checklist_items_modal',
    'score_band'
  ],
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    filteredObjectives() {
      return this.objectives.filter(objective => {
        const cardText = _.values(_.omit(objective, ['id'])).join()
        return cardText.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    },
  },
  methods: {
    pastObjectiveScore(objective) {
      return _.find(this.past_objective_scores, {
        mastery_objective_id: objective.id,
      })
    },
  },
})
