import Vue from 'vue/dist/vue.esm'
import template from './assessment_templates_template.slim'

Vue.component('assessment-templates', {
  mixins: [template],
  props: ['templates'],
  data() {
    return {
      filterBy: '',
      schoolSlug: '',
    }
  },
  computed: {
    newTemplatePath() {
      return `/go/${this.schoolSlug}/assessment_templates/new`
    },
    filteredTemplates() {
      return this.templates.filter(t => {
        return (
          t.title.toLowerCase().includes(this.filterBy.toLowerCase()) ||
          t.subject.toLowerCase().includes(this.filterBy.toLowerCase()) ||
          t.grade.toLowerCase().includes(this.filterBy.toLowerCase()) ||
          this.formatDate(t.created_at)
            .toLowerCase()
            .includes(this.filterBy.toLowerCase())
        )
      })
    },
  },
  methods: {
    baseTemplatePath(id) {
      return `/go/${this.schoolSlug}/assessment_templates/${id}`
    },
    formatDate(dateStr) {
      let date = dateStr.split('T')[0].split('-')
      let month = date[1]
      if (month.charAt(0) === '0') {
        month = month.charAt(1)
      }
      let day = date[2]
      if (day.charAt(0) === '0') {
        day = day.charAt(1)
      }
      let year = date[0]
      return month + '/' + day + '/' + year
    },
  },
  mounted() {
    this.schoolSlug = document.getElementById('app').dataset.schoolSlug
  },
})
