import Vue from 'vue/dist/vue.esm'
import template from './sidebar_item_template.slim'

Vue.component('sidebar-item', {
  props: ['name', 'href'],
  data: function() {
    return {
      isActive: window.location.href === this.href,
      icon: this.name + '-icon',
    }
  },
  mixins: [template],
  methods: {
    text() {
      return this.name.replace(/\-/g, ' ')
    },
  },
})
