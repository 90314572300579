import Vue from 'vue/dist/vue.esm'
import scoreTemplate from './score.slim'
import recentScoresTemplate from './recent-scores.slim'

const SCORE_OPTIONS = [
  { display: 'exemplary', value: 4, class: 'exemplary' },
  { display: 'yes', value: 3, class: 'yes' },
  { display: 'mostly', value: 2, class: 'mostly' },
  { display: 'somewhat', value: 1, class: 'somewhat' },
  { display: 'not yet', value: 0, class: 'no' },
];

Vue.component('score', {
  mixins: [scoreTemplate],
  props: ['scoreObject', 'score_band'],
  computed: {
    scoreOption() {
      return SCORE_OPTIONS.find((s) => s.value === this.scoreObject?.score);
    },
    scoreClass() {
      return this.scoreOption?.class ?? 'clear';
    }
  }
})

Vue.component('recent-scores', {
  mixins: [recentScoresTemplate],
  props: ['scores', 'score_band']
});
