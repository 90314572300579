import Vue from 'vue/dist/vue.esm'
import template from './active_goal_objectives_report_template.slim'
import { VueGoodTable } from 'vue-good-table'

const columns = [
  {
    label: 'Name',
    field: 'fullName',
    tdClass: 'teacher-name-td',
  },
  {
    label: 'Active Goal',
    field: 'activeGoal',
    html: true,
    tdClass: 'session-notes',
  },
]

Vue.component('active-goal-objectives-report', {
  mixins: [template],
  components: {
    VueGoodTable,
  },
  props: ['teacher_goal_rows'],
  data() {
    return {
      columns,
    }
  },
})
